@import './generated/variables.css';

:root {
  --z-index-explorer-layout: 9;
  --z-index-breadcrumbs: 11;
  --z-index-main-menu: 13;
  --z-index-sidebar: 14;
  --z-index-callout: 14;
  --z-index-contact-callout: 14;
  --z-index-navbar: 14;
  --z-index-sticky-nav: 14;
  /* --z-index-modal: 15 <-- globals */
  --z-index-dropdown: 17;
  --z-index-tooltip: 18;

  --sidebar-width: 72px;
}
