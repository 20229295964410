:root {
  --space-0: 0;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;
  --space-11: 64px;
  --space-12: 72px;
  --space-13: 80px;
  --space-14: 96px;
  --space-15: 112px;
  --space-16: 124px;
  --space-17: 152px;
}
