html {
  height: 100%;
  overflow-y: scroll;
}

body {
  height: 100%;
  font-family: Avenir, sans-serif;
  background-color: #f0f0f0;
}

@media print {
  body {
    background-color: #fff;
  }
}

a {
  display: inline-flex;
}

a > * {
  flex-grow: 1;
}

strong,
b {
  font-weight: bold;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}
